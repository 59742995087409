import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

export const EventCard = ({ event }) => {
  const navigate = useNavigate();
  const navigateToEvent = ({ id }) => {
    navigate(`/events/${id}`);
  };
  const eventImage = event.mainImage?.src;
  return (
    <Card
      sx={{
        width: "100%",
        ":hover": {
          boxShadow: "0 0 10px 5px rgba(0, 0, 0, 0.1)",
        },
      }}
      onClick={() => navigateToEvent(event)}
    >
      <CardMedia
        image={eventImage}
        alt={event.name}
        sx={{
          width: "100%",
          paddingTop: "65%",
          clipPath: "polygon(0 0, 100% 0%, 100% 80%, 0% 100%)",
          backgroundColor: "rgba(0, 0, 0, 0.08)",
        }}
      />
      <Typography
        gutterBottom
        variant="h5"
        elevation={6}
        sx={{
          px: 2,
          position: "relative",
          width: "fit-content",
          height: "fit-content",
          backgroundColor: "#fff",
          border: "2px solid green",
          borderRadius: "10px",
          margin: "-60px 32px 0 auto",
          "& > img": {
            margin: 0,
          },
        }}
      >
        {event.nextInstance
          ? `${new Date(
              event.nextInstance.startDate.seconds * 1000
            ).toLocaleDateString()} at ${new Date(
              event.nextInstance.startDate.seconds * 1000
            ).toLocaleTimeString()}`
          : "No upcoming dates"}
      </Typography>

      <CardContent>
        <Typography
          variant="h4"
          color="text.secondary"
          sx={{
            position: "relative",
            backgroundColor: "#fff",
            borderRadius: "10px",
            px: 2,
            py: 1,
            width: "fit-content",
            height: "fit-content",
            zIndex: 100,
          }}
        >
          {event.name}
        </Typography>
        <Typography variant="body1" mt={1}>
          {event.summary}
        </Typography>
      </CardContent>
      <CardActions
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          size="small"
          color="primary"
          onClick={() => navigateToEvent(event)}
        >
          Buy Tickets
        </Button>
      </CardActions>
    </Card>
  );
};
