import { Container, Grid, Link, Typography } from "@mui/material";

export const Footer = () => {
  return (
    <Container
      maxWidth="sm"
      sx={{
        mt: 4,
      }}
    >
      <Typography align="center" display="block" variant="overline">
        <Grid spacing={2} justifyContent={"center"} container>
          <Grid item>
            <Link href="/">Home </Link>
          </Grid>
          <Grid item>
            <Link href="/privacy">Privacy Policy </Link>
          </Grid>
          <Grid item>
            <Link href="/terms">Terms of Service </Link>
          </Grid>
          <Grid item>
            <Link href="/contact">Contact Us </Link>
          </Grid>
          <Grid item>
            <Link href="/signout">Sign Out </Link>
          </Grid>
        </Grid>
      </Typography>
      <Typography
        variant="overline"
        display="block"
        gutterBottom
        align="center"
      >
        Copyright 2024 ManageYour.Club LLC
      </Typography>
    </Container>
  );
};
