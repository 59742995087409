import { createTheme, responsiveFontSizes } from "@mui/material";

const primary = "#2c7e3f";
const secondary = "#ff3d00";

const black = "#343a40";
const darkBlack = "rgb(36, 40, 44)";
const fadeToBlack = "rgb(36, 40, 44, 0.5)";
const lightText = "rgba(255, 255, 255, 0.7)";
const background = "rgba(225,216,194,0.25)";
const warningLight = "rgba(253, 200, 69, .3)";
const warningMain = "rgba(253, 200, 69, .5)";
const warningDark = "rgba(253, 200, 69, .7)";

// border
const borderWidth = 2;
const borderColor = "rgba(0, 0, 0, 0.13)";

// breakpoints
const xl = 1920;
const lg = 1280;
const md = 960;
const sm = 600;
const xs = 0;

// spacing
const spacing = 6;

const theme = createTheme({
  palette: {
    primary: { main: primary },
    secondary: { main: secondary },
    tertiary: { main: lightText },
    common: {
      black,
      darkBlack,
      fadeToBlack,
      lightText,
    },
    warning: {
      light: warningLight,
      main: warningMain,
      dark: warningDark,
    },
    background: {
      default: background,
    },
    spacing,
  },
  breakpoints: {
    values: {
      xl,
      lg,
      md,
      sm,
      xs,
    },
  },
  border: {
    borderColor: borderColor,
    borderWidth: borderWidth,
  },
  overrides: {
    MuiExpansionPanel: {
      root: {
        position: "static",
      },
    },
    MuiToolbar: {
      root: {
        minHeight: 32,
        [`@media (max-width:  ${sm}px)`]: {
          minHeight: 28,
          paddingLeft: spacing,
          paddingRight: spacing,
        },
      },
    },
    MuiTableCell: {
      root: {
        paddingLeft: spacing * 2,
        paddingRight: spacing * 2,
        borderBottom: `${borderWidth}px solid ${borderColor}`,
        [`@media (max-width:  ${sm}px)`]: {
          paddingLeft: spacing,
          paddingRight: spacing,
        },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: borderColor,
        height: borderWidth,
      },
    },
    MuiPrivateNotchedOutline: {
      root: {
        borderWidth: borderWidth,
      },
    },
    MuiListItem: {
      divider: {
        borderBottom: `${borderWidth}px solid ${borderColor}`,
      },
    },
    MuiDialog: {
      paper: {
        width: "100%",
        maxWidth: 430,
        marginLeft: spacing,
        marginRight: spacing,
      },
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: primary,
        color: lightText,
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: darkBlack,
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        [`@media (max-width:  ${sm}px)`]: {
          paddingLeft: spacing,
          paddingRight: spacing,
        },
      },
    },
  },
  typography: {
    fontFamily: ["Work Sans", "Roboto", "sans-serif"].join(","),
    useNextVariants: true,
  },
});

export default responsiveFontSizes(theme);
