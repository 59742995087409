import { Link, useLocation } from "react-router-dom";
import {
  centsToCurrency,
  getMemberOrders,
  storage,
  updateProfile,
} from "../services/firebase";
import {
  enforceFormat,
  formatToPhone,
  timeToDateString,
} from "../services/utils";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { useCallback, useContext, useEffect, useRef, useState } from "react";

import { AppContext } from "../context/AppContext";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { BudPass } from "../components/BudPass";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import { Helmet } from "react-helmet";
import { MemberPasses } from "../components/MemberPasses";
import ReceiptIcon from "@mui/icons-material/ReceiptLong";
import Snackbar from "@mui/material/Snackbar";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

export const Profile = () => {
  const { member, memberPasses } = useContext(AppContext);
  const [imagePreview, setImagePreview] = useState(null);
  const location = useLocation();

  const [displayName, setDisplayName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailOptIn, setEmailOptIn] = useState(false);
  const [orders, setOrders] = useState([]);
  const [profileUpdated, setProfileUpdated] = useState(false);

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleCloseSnackbar = () => {
    setProfileUpdated(false);
  };

  useEffect(() => {
    if (!member) return;

    const fetchOrders = async () => {
      try {
        const ordersData = await getMemberOrders(member.id);
        setOrders(ordersData);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, [member]);

  useEffect(() => {
    if (!member) return;

    setDisplayName(member.displayName);
    setPhoneNumber(member.phoneNumber);
    setEmailOptIn(member.emailOptIn);
  }, [member]);

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [location]);

  const isAnnualPassHolder = memberPasses.some(
    (pass) => pass.status === "active" && pass.type === "year"
  );

  const onImageChange = useCallback(
    async (event) => {
      if (event.target.files && event.target.files[0]) {
        const img = event.target.files[0];
        setImagePreview(URL.createObjectURL(img));
        try {
          const imageRef = ref(storage, `members/${member.id}`);
          await uploadBytes(imageRef, img);
          const imageUrl = await getDownloadURL(imageRef);
          if (imageUrl) {
            await updateProfile(member, { photoURL: imageUrl });
            setProfileUpdated(true);
          }
        } catch (error) {
          console.error("Error uploading image:", error);
        }
      }
    },
    [member]
  );

  const onUpdateProfile = useCallback(async () => {
    try {
      await updateProfile(member, { displayName, phoneNumber, emailOptIn });
      setProfileUpdated(true);
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  }, [member, displayName, phoneNumber, emailOptIn]);

  if (!member) {
    return null;
  }

  return (
    <Container>
      <Helmet>
        <title>Your Profile - The Cola St. Louis</title>
      </Helmet>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        key="bottomcenter"
        onClose={handleCloseSnackbar}
        open={profileUpdated}
        message="Profile Updated!"
        autoHideDuration={5000}
      />
      <Card sx={{ my: 2, p: 2 }}>
        <CardHeader title="Update Your Profile" />
        <CardContent>
          <Grid container spacing={2}>
            <Grid
              item
              xs={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Avatar
                sizes="large"
                sx={{
                  width: "20vw",
                  maxWidth: "100px",
                  height: "20vw",
                  maxHeight: "100px",
                }}
                alt={displayName}
                src={imagePreview || member.photoURL}
                onClick={handleButtonClick}
              />
              <input
                id="imageInput"
                type="file"
                accept="image/*"
                ref={fileInputRef}
                onChange={onImageChange}
                style={{ display: "none" }}
              />
              <Button
                size="small"
                fullWidth
                variant="text"
                color="primary"
                sx={{ mt: 1 }}
                onClick={handleButtonClick}
              >
                Upload
              </Button>
            </Grid>
            <Grid item xs={9}>
              <Box sx={{ mb: 1 }}>
                <TextField
                  label="Name"
                  value={displayName}
                  fullWidth
                  onChange={(e) => setDisplayName(e.target.value)}
                />
              </Box>
              <Box sx={{ mb: 1 }}>
                <TextField
                  label="Phone"
                  value={phoneNumber}
                  fullWidth
                  onKeyUp={formatToPhone}
                  onKeyDown={enforceFormat}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </Box>
              <Box sx={{ mb: 1 }}>
                <FormControlLabel
                  control={
                    <Switch
                      sx={{ mr: 1 }}
                      checked={emailOptIn}
                      onChange={(e) => setEmailOptIn(e.target.checked)}
                    />
                  }
                  label="Send me email about events and promotions"
                />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Box textAlign="right" flexGrow={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={onUpdateProfile}
            >
              Update
            </Button>
          </Box>
        </CardActions>
      </Card>
      <Card sx={{ my: 2, p: 2 }}>
        <CardHeader title="Membership" />
        <CardContent>
          <Typography variant="body1">
            <strong>Email: </strong> {member.email}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Joined: </strong> {timeToDateString(member.createdate)}
          </Typography>
          {isAnnualPassHolder && <BudPass />}
          <a id="membership" title="membership" />
          <MemberPasses />
        </CardContent>
      </Card>
      <Card sx={{ my: 2, p: 2 }}>
        <CardHeader title="Orders" />
        <CardContent>
          {orders.length === 0 ? (
            <Typography variant="body1">You have no orders.</Typography>
          ) : (
            orders.map((order) => (
              <Box
                key={order.id}
                sx={{
                  mb: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #ccc",
                  pb: 1,
                }}
              >
                <Typography variant="body1">
                  {timeToDateString(order.createdate)}
                </Typography>
                <Typography variant="body1">
                  {centsToCurrency(order.amount)}
                </Typography>
                <Link to={`/receipt/${order.id}`}>
                  <ReceiptIcon />
                </Link>
              </Box>
            ))
          )}
        </CardContent>
      </Card>
    </Container>
  );
};
