import React, { useContext, useEffect, useState } from "react";
import { centsToCurrency, getEvent } from "../services/firebase";
import { useNavigate, useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { CartContext } from "../context/CartContext";
import Container from "@mui/material/Container";
import ExpandableText from "../components/ExpandableText";
import Grid from "@mui/material/Grid";
import { Helmet } from "react-helmet";
import PlaceTwoTone from "@mui/icons-material/PlaceTwoTone";
import { QuantitySelector } from "../components/QuantitySelector";
import ReactHtmlParser from "react-html-parser";
import ShoppingCartCheckoutTwoTone from "@mui/icons-material/ShoppingCartCheckoutTwoTone";
import Typography from "@mui/material/Typography";
import Carousel from "react-material-ui-carousel";

const optionsDate = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};
const optionsTime = { hour: "numeric", minute: "2-digit", hour12: true };

export const EventDetail = () => {
  const { eventSlug } = useParams();
  const navigate = useNavigate();
  const { addToCart, removeFromCart, getCartItems } = useContext(CartContext);

  const [event, setEvent] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [hasTicketsInCart, setHasTicketInCart] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
    getEvent(eventSlug).then((event) => {
      if (event && !event.error) {
        event.tickets = event.tickets.map((ticket) => ({
          ...ticket,
          id: ticket.sku,
        }));
        event.additionalImages = event.additionalImages || [];
        setEvent(event);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    });
  }, [eventSlug]);

  useEffect(() => {
    const cartItems = getCartItems();
    if (event.tickets?.length > 0) {
      setHasTicketInCart(
        cartItems.some((item) =>
          event.tickets.some((ticket) => ticket.sku === item.sku)
        )
      );
    }
  }, [event, getCartItems]);

  if (isLoading) {
    return <p>Loading...</p>;
  }
  if (isError) {
    return (
      <p>
        Sorry, we couldn't find that event. Check our
        <a href="/events"> Event List</a>
      </p>
    );
  }

  const formattedDate = event.startDate
    .toDate()
    .toLocaleDateString("en-US", optionsDate);
  const formattedStartTime = event.startDate
    .toDate()
    .toLocaleTimeString("en-US", optionsTime);
  const formattedEndTime = event.endDate
    .toDate()
    .toLocaleTimeString("en-US", optionsTime);

  const checkout = () => {
    const cartItems = getCartItems();
    if (cartItems.length === 0) {
      addToCart(event.tickets[0]);
    }
    navigate("/checkout");
  };

  const allImages = [event.mainImage, ...event.additionalImages];
  const defaultImage = allImages.length > 0 ? allImages[0] : null;

  return (
    <Container fixed maxWidth="md">
      <Helmet>
        <title> {event.name} at The Cola St. Louis</title>
        <meta
          property="og:title"
          content={`${event.name} at The Cola St. Louis`}
        />
        <meta property="og:description" content={event.summary} />
        {defaultImage ? (
          <meta property="og:image" content={defaultImage} />
        ) : null}
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
      </Helmet>
      <Box sx={{ my: 2 }}>
        <Grid container spacing={0} sx={{ pb: 20 }}>
          <Grid item xs={12} mb={2}>
            <Typography variant="h4">{event.name}</Typography>
          </Grid>
          <Grid xs={12} md={6} item>
            {allImages.length ? (
              <Carousel animation="slide">
                {allImages.map((image, index) => (
                  <Paper key={index} elevation={3}>
                    <img
                      src={image.src}
                      alt={image.title}
                      style={{ width: "100%", height: "100%", minHeight: 250 }}
                    />
                  </Paper>
                ))}
              </Carousel>
            ) : null}
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ p: 2 }}>
              <Typography variant="h5">
                <strong>{formattedDate}</strong> <br />
                from {formattedStartTime} to {formattedEndTime}
              </Typography>
              <Box sx={{ mt: 2, display: "flex" }}>
                <PlaceTwoTone fontSize="large" />
                <Typography variant="body2" ml={2}>
                  <strong>{event.location?.name}</strong>
                  <br />
                  <a href="https://www.google.com/maps/dir//2834+Cherokee+St,+St.+Louis,+MO+63118/@38.59383,-90.3139245,12z/">
                    {event.location?.address}
                  </a>
                </Typography>
              </Box>
            </Box>
            <Box p={2}>{ReactHtmlParser(event?.description)}</Box>
          </Grid>
        </Grid>
      </Box>
      <Grid
        container
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          p: 1,
          px: 2,
          zIndex: 1000,
          bgcolor: "grey.200",
          borderTop: "1px solid",
          borderTopColor: "grey.300",
        }}
      >
        {event.tickets.length > 0 && (
          <>
            <Grid xs={12} item>
              <Box>
                {event.tickets.map((ticket) => (
                  <Box
                    key={ticket.sku}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 1,
                    }}
                  >
                    <Box sx={{ flexGrow: 1, mr: 1 }}>
                      <Typography variant="h6">{ticket.name}</Typography>
                      <ExpandableText text={ticket?.description} />
                    </Box>
                    <Box sx={{ textAlign: "right" }}>
                      <Typography variant="body2">
                        {centsToCurrency(ticket.amount)}
                      </Typography>
                    </Box>
                    <QuantitySelector
                      item={
                        getCartItems().find(
                          (item) => item.sku === ticket.sku
                        ) || {
                          ...ticket,
                          quantity: 0,
                        }
                      }
                      remove={() => removeFromCart(ticket)}
                      add={() => addToCart(ticket)}
                    />
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid xs={12} item textAlign={"right"}>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={checkout}
                endIcon={<ShoppingCartCheckoutTwoTone />}
                disabled={!hasTicketsInCart}
              >
                Checkout
              </Button>
            </Grid>
          </>
        )}
        {event.ticketlink && (
          <Grid xs={12} item textAlign={"center"}>
            <Button
              component={"a"}
              href={event.ticketlink}
              target="_blank"
              size="large"
              variant="contained"
              color="primary"
              endIcon={<ShoppingCartCheckoutTwoTone />}
            >
              Buy Tickets
            </Button>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};
